import dynamic from 'next/dynamic';
import { usePage } from '~/services';
import { ClientErrorCatcher } from './utils';
import { PageTypes } from '.';

const pageTypes: PageTypes = {
    p10SearchPage: dynamic(() => import('./components/P10SearchPage')),
    p11PopularProductsPage: dynamic(() => import('./components/P11PopularProductsPage')),
    p14BrandOverviewPage: dynamic(() => import('./components/P14BrandOverviewPage')),
    p20FrontPage: dynamic(() => import('./components/P20FrontPage')),
    p21FrontPageLouisPoulsen: dynamic(() => import('./components/P20FrontPage')),
    p22FrontPageFogKonto: dynamic(() => import('./components/P20FrontPage')),
    productFilterPage: dynamic(() => import('./components/P40ProductListPage')),
    categoryNode: dynamic(() => import('./components/P40ProductListPage')),
    categoryListPage: dynamic(() => import('./components/P31CategoryListPage')),
    p50ProductDetailPage: dynamic(() => import('./components/P50ProductDetailPage')),
    p60ModulePage: dynamic(() => import('./components/P60ModulePage')),
    p90BasketPage: dynamic(() => import('./components/P90BasketPage')),
    p100CheckoutInformationPage: dynamic(() => import('./components/P100CheckoutInformationPage')),
    p101CheckoutDeliveryPage: dynamic(() => import('./components/P101CheckoutDeliveryPage')),
    p103CheckoutReceiptPage: dynamic(() => import('./components/P103CheckoutReceiptPage')),
    p140NotFoundPage: dynamic(() => import('./components/P140NotFoundPage')),
    p160ArticlePage: dynamic(() => import('./components/P160ArticlePage')),
    p170NotAllowedPage: dynamic(() => import('./components/P170NotAllowedPage')),
    p180NotAuthenticatedPage: dynamic(() => import('./components/P180NotAuthenticatedPage')),
    p200MitFogPage: dynamic(() => import('./components/P200MitFogPage')),
};

export default function DynamicPage() {
    const { data: page } = usePage();
    const PageType = (page?.type && pageTypes[page.type]) || pageTypes.p60ModulePage;

    return (
        <ClientErrorCatcher>
            <PageType />
        </ClientErrorCatcher>
    );
}
